import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/ui/Button';
import { Modal } from '../../../components/ui/Modal';
import { ButtonTypes } from '../../../components/ui/Button/button-types';

// Types
interface AlertQuizModalProps {
  readonly close: () => void;
  readonly isOpen: boolean;
  readonly buttonAction: () => void;
  readonly attempt: number;
}

function AlertQuizModal({
  isOpen,
  close,
  buttonAction,
  attempt
}: AlertQuizModalProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Modal.Root animation={true} show={isOpen} onHide={close}>
      <Modal.Header>
        {t('nacional-project.modal.alert-quiz-title')}
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>{t('nacional-project.modal.alert-quiz-subtitle-1')}</p>
          <p>
            <strong>
              {t('nacional-project.modal.alert-quiz-subtitle-2', {
                attemptUser: 2 - (attempt || 0)
              })}
            </strong>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          buttonType={ButtonTypes.Primary}
          style={{ height: '48px', width: '100%' }}
          onClick={buttonAction}
        >
          {t('nacional-project.modal.alert-quiz-button')}
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
}

AlertQuizModal.displayName = 'AlertQuizModal';

export default AlertQuizModal;
